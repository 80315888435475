import { lazy, Suspense, useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AuthContext } from "./Context/Context";
import Cookies from "js-cookie";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
const Main = lazy(() => import("./Page/Main/Main"));
const Home = lazy(() => import("./Page/Home/Home"));
const Login = lazy(() => import("./Page/Login/Login"));
const Category = lazy(() => import("./Page/Category/Category"));
const Products = lazy(() => import("./Page/Products/Products"));
const ProductsDetails = lazy(() => import("./Page/Category/Details"));
const Contact = lazy(() => import("./Page/Contact/Contact"));

function App() {
  const location = useLocation();
  const { loader } = useContext(AuthContext);
  let access_token = Cookies.get("access_token");
  return (
    <div className="App">
      {loader && (
        <div className="bg_loader">
          <span className="loader"></span>
        </div>
      )}
      <Suspense
        fallback={
          <div className="bg_loader">
            <span className="loader"></span>
          </div>
        }
      >
        <Routes location={location} key={location.pathname}>
          <Route index path="/Login" element={<Login />}></Route>
          <Route path="/" element={<Login />}></Route>
          <Route path="/*" element={<Navigate to="/Login" />}></Route>
          {access_token && (
            <Route path="Dashboard" element={<Main />}>
              <Route path="Home/*" element={<Home />}></Route>
              <Route path="Category" element={<Category />} />
              <Route path="Products" element={<Products />} />
              <Route path="Contact" element={<Contact />} />
              <Route path="Category/:id" element={<ProductsDetails />}></Route>
            </Route>
          )}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
