import axios from "axios";
import { createContext, useState } from "react";
// import { API } from "../Config/Api";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Toast from "../Component/Sweetalert/Sweetalert";
import { API } from "../Config/Api";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const nav = useNavigate();
  let access_token = Cookies.get("access_token");
  const [trueEdit, setTrueEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleLogout = (e) => {
    e.preventDefault();
    axios
      .post(
        API.Admin.logout,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          Toast.fire({
            icon: "success",
            text: `${res.data.message}`,
          });
          Cookies.remove("access_token");
          Cookies.remove("User");
          Cookies.remove("fcmToken");
          nav("/Login");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err?.response?.data?.message === "Unauthenticated.") {
          Toast.fire({
            icon: "error",
            text: `${
              err?.response?.data?.message === "Unauthenticated." &&
              "انتهت صالحية الجلسة"
            }`,
          });
          Cookies.remove("access_token");
          nav("/Login");
        }
      });
  };
  return (
    <AuthContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        handleLogout,
        trueEdit,
        setTrueEdit,
        loader,
        setLoader,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
